<template>
<v-container>
        <div v-if="board" class="center-area">
            <div class="center-board">
                <div class="board-container">
                    <div role="table" class="board-table board-table--view">
                        <div role="rowgroup">
                            <div role="row">
                                <div role="columnheader">
                                    {{ board.subject }}
                                </div>
                            </div>
                            <div role="row">
                                <div role="cell">
                                    <ul class="board-view-data">
                                        <li>작성자 : {{ board.writer.name }}</li>
                                        <li>작성일 : {{ board.createdAt.toDate() }}</li>
                                        <li>조회수 : {{ board.viewCount }}</li>
                                    </ul>
                                </div>
                            </div>
                            <div v-show="false" role="row">
                                <div role="cell">
                                    <div class="board-view-file">
                                        <span class="head">
                                            <svg
                                                version="1.1"
                                                id="레이어_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 409 513.6"
                                                style="enable-background:new 0 0 409 513.6;"
                                                xml:space="preserve"
                                            >
                                                <path
                                                    d="M41.5,452.8v-353c0-11,9-20,20-20h60v-19c0-11,9-20,20-20c11,0,20,9,20,20v141c0,11-9,20-20,20c-11,0-20-9-20-20v-82h-40v82
                                    c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60v-122h145.9c11,0,20,9,20,20v353c0,11-9,20-20,20H61.5C50.5,472.8,41.5,463.8,41.5,452.8
                                    M85.3,39.8H61.5c-33.1,0-60,26.9-60,60v353c0,33.1,26.9,60,60,60h285.9c33.1,0,60-26.9,60-60v-353c0-33.1-26.9-60-60-60H197.7
                                    c-8.5-22.8-30.5-39-56.2-39S93.9,17,85.3,39.8"
                                                />
                                            </svg>
                                            첨부파일 :
                                        </span>
                                        <ul v-show="false" class="lists">
                                            <li><a href="">파일제목_01.jpg,</a></li>
                                            <li><a href="">파일제목_02.jpg,</a></li>
                                            <li><a href="">파일제목_03.jpg,</a></li>
                                            <li><a href="">파일제목_04.jpg,</a></li>
                                            <li><a href="">파일제목_05.jpg</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div role="row">
                                <div role="cell">
                                    <div class="board-content">
                                        <img :src="board.thumb" >
                                        <div v-html="board.content"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="false" role="table" class="board-table board-table--preview">
                        <div role="rowgroup">
                            <div role="row">
                                <div role="columnheader">
                                    이전글
                                </div>
                                <div role="cell">
                                    <div class="title"><a href="">이전 게시글 제목입니다.</a></div>
                                </div>
                            </div>
                            <div role="row">
                                <div role="columnheader">
                                    이전글
                                </div>
                                <div role="cell">
                                    <div class="title"><a href="">다음 게시글 제목입니다.</a></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="board-buttons">
                        <div class="left">
                            <a class="button button--border" @click="$router.go(-1)"><span>리스트</span></a>
                        </div>
                        <div v-show="false" class="right">
                            <a class="button button--border" href=""><span>수정</span></a>
                            <a class="button button--border" href=""><span>답글</span></a>
                            <a class="button button--primary" href=""><span>삭제</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import CommentList from "../../comment-list.vue";

export default {
    components: {
        CommentList,
    },
    props: {
        board: { type: Object, default: null },
        me: { type: Object, default: null },
    },
    computed: {
        isBookmark() {
            return this.me ? this.me.bookmarks.find(bookmark => bookmark._board == this.board._id) : false;
        },
        isLike() {
            return this.me ? this.me.likes.find(like => like._board == this.board._id) : false;
        },
    },
};
</script>
